<template>
    <div>
        <slot name="title"></slot>
        <h1>I am the header 1</h1>
        <slot name="text"></slot>
    </div>
</template>

<script>
export default {
    name: "SlotsSimple",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
h1 {
    color: blue;
}
h2 {
    color: red;
}
</style>
