<template>
    <div id="add-blog">
        <h2>Add a new blog post</h2>
        <form>
            <!-- input binding -->
            <label>Blog Title:</label>
            <input type="text" v-model.lazy="blog.title" required>

            <!-- text area binding -->
            <label>Blog Content:</label>
            <textarea v-model.lazy="blog.content" cols="30" rows="10"></textarea>

            <!-- checkbox binding -->
            <div id="checkboxes">
                <input type="checkbox" value="Ninjas" v-model="blog.categories"/>
                <label>Ninjas</label>
                <input type="checkbox" value="Wizards" v-model="blog.categories"/>
                <label>Wizards</label>
                <input type="checkbox" value="Mario" v-model="blog.categories"/>
                <label>Mario</label>
                <input type="checkbox" value="Mushroom" v-model="blog.categories"/>
                <label>Mushroom</label>
            </div>

            <!-- select box binding -->
            <select name="" id="" v-model="blog.author">
                <label for="">Author:</label>
                <option v-for="author in blog.authors">{{author}}</option>
            </select>
        </form>

        <!-- Preview data of the blog -->
        <div id="preview">
            <h3>Preview Blog</h3>
            <!-- input -->
            <p>Blog title: <b>{{blog.title}}</b></p>
            <!-- textarea -->
            <p>Blog content:</p>
            <p>{{blog.content}}</p>
            <!-- checkbox -->
            <p>Blog categories:</p>
            <ul>
                <li v-for="category in blog.categories">{{category}}</li>
            </ul>
            <!-- select box -->
            <p>Author of this blog: <b>{{blog.author}}</b></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddBlog",
    components: {},
    props: {},
    data() {
        return {
            blog: {
                title: "",
                content: "",
                categories: [],
                author: "",
                authors: ["Monica Lewinski", "Theodor Kowalski", "Adam Frommer", "Christian Slater"],
            }
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
#add-blog * {
    box-sizing: border-box;
}

#add-blog {
    margin: 20px auto;
    max-width: 500px;
}

label {
    display: block;
    margin: 20px 0 10px;
}

input[type="text"], textarea {
    display: block;
    width: 100%;
    padding: 8px;
}

#preview {
    padding: 10px 20px;
    border: 1px dotted #323333;
    margin: 30px 0;
}

h3 {
    margin-top: 10px;
}

#checkboxes {
    display: inline-block;
    margin-right: 20px;
}

#checkboxes label {
    display: inline-block;
    margin-left: 5px;
    margin-right: 10px;
}
</style>
