<template>
    <div>
        <h1>Show blogs</h1>
<!--        <pre>{{records}}</pre>-->
        <ul>
            <li v-for="record in records">
                <h2>{{record.title}}</h2>
                <article>{{record.body}}</article>
            </li>
        </ul>



    </div>
</template>

<script>
// fetch('https://jsonplaceholder.typicode.com/posts')
//     .then(response => response.json())
//     .then(json => console.log(json))

export default {
    name: "Blogs",
    components: {},
    props: {},
    data() {
        return {
            records: [],
        }
    },
    mounted() {
        this.callApi();
    },
    methods: {
        callApi() {
            this.axios.get('https://jsonplaceholder.typicode.com/posts')
                .then((response) => {
                    this.records = response.data.slice(0,10);
                    // this.$set(this, 'records', response.data);
                    console.log("response", response.data); // TODO delete
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                    // this.startingCity = "no zip code"
                });
        },
    },
    computed: {},
    filters: {},
    watch: {}
}

</script>

<style scoped>

</style>
