<template>
    <div>
        <h1>Hello, {{fullName}}!</h1>
        <ul>
            <li>First name: {{firstName}}</li>
            <li>Last name: {{lastName}}</li>
        </ul>
        <form>
            <label>First Name:</label>
            <input type="text" v-model="firstName">
        </form>
        <form>
            <label>Last Name:</label>
            <input type="text" v-model="lastName">
        </form>
        <hr>
        <br>
        <form>
            <label>Full Name:</label>
            <input type="text" v-model="fullName">
        </form>
    </div>
</template>

<script>
export default {
    name: "Computed",
    components: {},
    props: {},
    data() {
        return {
            firstName: '',
            lastName: ''
        }
    },
    mounted() {
    },
    methods: {},
    computed: {
        fullName: {
            get() {
                return this.firstName + ' ' + this.lastName
                // return `${this.firstName} ${this.lastName}`
            },
            //setter function
            set(value) {
                let name = value.split(' ')
                console.log("value:", value, "name:", name); // TODO delete
                this.firstName = name[0]
                this.lastName = name[1]
            }
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
