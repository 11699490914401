<template>
    <div class="board-pd vh-100">
        <div class="panel panel-1">
            <div class="subpanel subpanel-1">Experiences</div>
            <div class="subpanel subpanel-2">Details</div>
        </div>
        <div class="panel panel-2">
            Map
        </div>
    </div>
</template>

<script>
export default {
    name: "BoardPd",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.board-pd {
    background-color: lightpink;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.panel {
    background-color: lightskyblue;
    width: 50%;
    border: 2px solid darkblue;
    display: flex;

}

.panel-1 {
    margin-right: 10px;
}

.panel-2 {

}

.subpanel {
    background-color: limegreen;
    width: 50%;
    border: 2px solid green;

}

.subpanel-1 {
flex-grow: 2;
}

.subpanel-2 {
    width: 300px;
}

</style>
