<template>
    <div class="center">
        <h1>My personal skills</h1>

        <!-- v-model -->
        <form @submit.prevent="changeName">
            <input type="text" placeholder="Enter your name..." v-model="name">
        </form>
        <p>My name is <span style="font-size: 1.9em; color: rebeccapurple"
                            class="font-italic font-weight-bolder">{{name}}</span> and my skills are:</p>

        <!-- v-bind & v-on:click -->
        <!--        <br>
                {{btnState ? 'The button is disabled' : 'Active button! Yay! \:)'}}
                <br>
                <b-button v-on:click="changeName" v-bind:disabled="btnState">Change name</b-button>

                <div v-bind:class="{ alert: showAlert }"></div>
                <div v-bind:class="alertObject">
                    <div v-bind:style="{ backgroundColor: bgColor, width: bgWidth, height: bgHeight }">
                        <div>-->

        <!-- v-model -->
        <form @submit.prevent="addSkill">
            <input type="text" placeholder="Enter a skill you have:" v-model="skill">
        </form>

        <br><br>
        <!-- v-for -->
        <ul>
            <li v-for="(data, index) in skills" :key="index">{{index + 1}}. {{data.skill}}</li>
        </ul>

        <!-- v-if -->
        <p v-if="skills.length > 1">Congratulations! You've got more than one skill.</p>
        <p v-else-if="skills.length === 1">Yaaaay... You've got one skill. One. Oh, go and learn some more.</p>
        <p v-else>You have zero skills. Go away and learn some! </p>

        <!-- v-show -->
        <div style="width: 50px; height: 50px; background-color: blue" v-show="square"></div>
        <div v-text="msg"></div>
        <div v-html="intro"></div>

        <!-- v-once -->
        <!--        <p v-once> The value of the input box, rendered once: {{msg}}</p>
                <p> The value of the input box, rendered every time: {{msg}}</p>
                <input type="text" v-model="msg">-->

        <!-- v-bind -->
        <!--        <img v-bind:src="url" :title="title" :alt="title">-->

        <!-- v-for -->
        <!--        <ul>
                    <li v-for="(todo, index) in todos">{{index}}. {{todo.text}}</li>
                </ul>-->

    </div>
</template>

<script>
export default {
    name: "Skills",
    components: {},
    props: {},
    data() {
        return {
            todos: [
                {text: 'Learn Vue'},
                {text: 'Code something'},
                {text: 'Commit do Git'}
            ],
            // url: "http://vuejs.org/images/logo.png",
            title: "Page loaded.",
            square: false,
            intro: 'Welcome to <b>your skill set</b>, darling!',
            msg: 'Hello from Vue',
            skill: '',
            // name: 'Mojčica',
            name: '',
            btnState: false,
            skills: '',
            //     [
            //     // {"skill": "Project architecture"},
            //     // {"skill": "Design"},
            //     // {"skill": "Frontend Developer"},
            //     // {"skill": "Writing"},
            // ],
            showAlert: true,
            // showClass: true,
            // alertObject: {
            //     alert: true,
            //     another_class: true,
            // }
            bgColor: 'yellow',
            bgWidth: '50%',
            bgHeight: '100%'
        }
    },
    mounted() {
    },
    methods: {

        addSkill() {
            this.skills.push({skill: this.skill})
            this.skill = '';
        },
        // addName() {
        //     this.names.push({name: this.name})
        //     this.name = '';
        // }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.alert {
    background-color: yellow;
}

.another_class {
    border: dotted 2px green;
}

.holder {
    background: #fff;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul li {
    padding: 20px;
    font-size: 1.3em;
    background-color: #E0EDF4;
    border-left: 5px solid #3B3F6F;
    margin-bottom: 2px;
    color: #3E5252;
}

p {
    text-align: center;
    padding: 30px 0;
    color: #323333;
    font-size: 1.3em;
}

.container {
    box-shadow: 0 0 40px lightgray;
}

#app {
    width: 50%;
}

body, html {
    margin: 0;
    height: 100%;
}

body {
    display: grid;
    grid-template-rows: auto;
    justify-items: center !important;
    align-items: center;
}

input {
    width: calc(100% - 40px);
    border: 0;
    padding: 20px;
    font-size: 1.3em;
    background-color: #323333;
    color: #687F7F;

}
</style>
