<template>
    <div id="ninja-footer">
        <p>{{copyright}} {{title}}</p>
    </div>
</template>

<script>
export default {
    name: "NinjaFooter",
    components: {},
    props: {
        title: {
            type: String
        }
    },
    data() {
        return {
            copyright: 'Copyright 2023 ',
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
#ninja-footer {
    background: mediumvioletred;
}

p {
    padding: 10px;
    color: lightgreen;
    text-align: center;
}
</style>
