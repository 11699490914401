<template>
    <div>
        <!-- v-on -->
        <hr>
        <h1>Count: {{count}}</h1>
        <b-button @click="countUp">Count UP!</b-button>
        <b-button @click="countDown">Count down :(</b-button>

        <br><br>

        <!-- v-on && v-bind: how to clean up/humanize url -->
        Visit: <a v-bind:href="url" style="color: #323333">{{cleanUrl}}</a>
        <b-input type="text" v-model="url"></b-input>
        <b-button @click="humanizeUrl">Humanize me!</b-button>

    </div>
</template>

<script>
export default {
    name: "Events",
    components: {},
    props: {},
    data() {
        return {
            url: '',
            cleanUrl: '',
            count: 0,
        }
    },
    mounted() {
    },
    methods: {
        humanizeUrl: function () {
            this.cleanUrl = this.url.replace(/^https?:\/\//, '').replace(/\/$/, '')
        },
        countUp: function () {
            this.count += 1
        },
        countDown: function () {
            this.count -= 1
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
