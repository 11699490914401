<template>
    <div>
        <h1>You are: {{level}}</h1>
        <hr>
        <h2>Current XP: {{xp}}</h2>
        <b-button @click="addXP" style="margin-top: 10px">
            <b-icon-arrow-up></b-icon-arrow-up>
            Add XP
        </b-button>
        <b-button @click="decreaseXP" style="margin: 10px 0 0 10px">
            <b-icon-arrow-down></b-icon-arrow-down>
            Decrease XP
        </b-button>

        <b-button @click="setXp($event, xp)" style="margin: 10px 0 0 10px">Set</b-button>
    </div>
</template>

<script>
export default {
    name: "ComputedXp",
    components: {},
    props: {},
    data() {
        return {
            xp: 10,
        }
    },
    mounted() {
    },
    methods: {
        addXP() {
            this.xp += 10
        },
        decreaseXP() {
            this.xp -= 10
        },
        setXp(e, xp) {
            console.log(xp, e); // TODO delete
            this.level = 200;
        },
    },
    computed: {
        level: {
            get() {
                if (this.xp >= 200) {
                    return "Pro"
                } else if (this.xp >= 100) {
                    return "Intermediate"
                } else if (this.xp >= 0) {
                    return "Beginner"
                } else {
                    return "Noob"
                }
            },
            set(value) {
                this.xp = value;

            }
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
