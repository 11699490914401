<template>
    <div class="min-vh-100 p-3 playground">
        <!--        <skills></skills>-->
        <!--        <board-pd></board-pd>-->
        <!--        <events></events>-->
        <!--        <computed></computed>-->
        <!--        <computed-xp></computed-xp>-->
        <!--        <postal-codes></postal-codes>-->
        <!--        <ninja-header :title="title" @changeTitle="updateTitle($event)"></ninja-header>-->
        <!--        <ninja-list :ninjas="ninjas"></ninja-list>-->
        <!--        <ninja-footer :title="title"></ninja-footer>-->
        <!--        <events-ninja></events-ninja>-->
        <!--        <slots-simple>
                    <h2 slot="title">{{title}}</h2>
                    <p slot="text">I am text.</p>
                </slots-simple>-->

        <!-- slots - forms -->
        <!--        <keep-alive>
                    <component :is="component"></component>
                </keep-alive>
                <b-button @click="component = 'form-one'">Show Form One</b-button>
                <b-button @click="component = 'form-two'">Show Form Two</b-button>-->

        <!-- blogs NetNinja -->
        <!--        <add-blog></add-blog>-->
        <blogs></blogs>
    </div>
</template>

<script>

import Skills from "@/components/developer/mojca/Skills";
import BoardPd from "@/components/developer/mojca/BoardPd";
import Events from "@/components/developer/mojca/Events";
import Computed from "@/components/developer/mojca/Computed";
import ComputedXp from "@/components/developer/mojca/ComputedXp";
import PostalCodes from "@/components/developer/mojca/PostalCodes";
import NinjaHeader from "@/components/developer/mojca/NetNinja/NinjaHeader";
import NinjaList from "@/components/developer/mojca/NetNinja/NinjaList";
import NinjaFooter from "@/components/developer/mojca/NetNinja/NinjaFooter";
import EventsNinja from "@/components/developer/mojca/EventsNinja";
import SlotsSimple from "@/components/developer/mojca/SlotsSimple";
import SlotsForm from "@/components/developer/mojca/SlotsForm";
import FormOne from "@/components/developer/mojca/NetNinja/FormOne";
import FormTwo from "@/components/developer/mojca/NetNinja/FormTwo";
import AddBlog from "@/components/developer/mojca/NetNinja/AddBlog";
import Blogs from "@/components/developer/mojca/NetNinja/Blogs";

export default {
    name: "SandboxM",
    components: {
        Blogs,
        AddBlog,
        FormTwo,
        FormOne,
        SlotsForm,
        SlotsSimple,
        EventsNinja,
        NinjaFooter,
        NinjaList,
        NinjaHeader,
        PostalCodes,
        ComputedXp,
        Computed,
        Events,
        BoardPd,
        Skills
    },
    props: {},
    data() {
        return {
            ninjas: [
                {name: 'Ryu', speciality: 'Vue Components', show: false},
                {name: 'Crystal', speciality: 'HTML Wizardry', show: false},
                {name: 'Hitoshi', speciality: 'Click Events', show: false},
                {name: 'Tango', speciality: 'Conditionals', show: false},
                {name: 'Kami', speciality: 'Webpack', show: false},
                {name: 'Yoshi', speciality: 'Data Diggin', show: false}
            ],
            title: "Vue Ninjas",
            component: "form-one"
        }
    },
    mounted() {
    },
    methods: {
        updateTitle(updatedTitle) {
            this.title = updatedTitle;
        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.playground {
    background-color: lightskyblue;
}
</style>
