<template>
    <!-- simple adding and subtracting -->
<!--    <div>
        <b-button @click="age++">Add a year</b-button>
        <b-button @click="age&#45;&#45;">Subtract a year</b-button>
        <p>My age is {{age}}</p>
    </div>-->
    <!-- adding and subtracting using simple methods -->
<!--        <div>
            <b-button @click="add">Add a year</b-button>
            <b-button @click="subtract">Subtract a year</b-button>
            <p>My age is {{age}}</p>
        </div>-->
    <!-- adding and subtracting using not so simple methods -->
    <div>
        <b-button @click="add(1)">Add a year</b-button>
        <b-button @click="subtract(1)">Subtract a year</b-button>
        <b-button @dblclick="add(10)">Add 10 years</b-button>
        <b-button @dblclick="subtract(10)">Subtract 10 years</b-button>
        <p>My age is {{age}}</p>
        <!-- mouse move event -->
        <div id="canvas" @mousemove="updateXY">
            {{x}}, {{y}}
        </div>
    </div>
</template>

<script>
export default {
    name: "EventsNinja",
    components: {},
    props: {},
    data() {
        return {
            age: 45,
            x: 0,
            y: 0,
        }
    },
    mounted() {
    },
    methods: {
        // <-- using simple methods -->
        /*add() {
            this.age++
        },
        subtract() {
            this.age--
        },*/
        // <-- using not so simple methods -->
        add(inc) {
            this.age += inc;
        },
        subtract(dec) {
            this.age -= dec;
        },
        updateXY(event) {
            console.log(event); // TODO delete
            this.x = event.offsetX;
            this.y = event.offsetY;

        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
#canvas {
    width: 600px;
    padding: 200px 20px;
    text-align: center;
    border: 3px solid #7C9848;
}
</style>
