<template>
    <div>
        <slots-form>
            <div slot="form-header">
                <h3>Form Two - Log In</h3>
                <p>Enter your details to log-in</p>
            </div>
            <div slot="form-fields">
                <input type="text" placeholder="username" required />
                <input type="password" placeholder="password" required />
            </div>
            <div slot="form-controls">
                <button v-on:click="handleSubmit">Login</button>
            </div>
        </slots-form>
    </div>
</template>

<script>
import SlotsForm from "@/components/developer/mojca/SlotsForm";
export default {
    name: "FormTwo",
    components: {SlotsForm},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        handleSubmit: function () {
            alert('thanks for logging in (form two)');
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
