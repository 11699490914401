<template>
    <header>
        <h1 @click="changeTitle">{{title}}</h1>
    </header>
</template>

<script>
export default {
    name: "NinjaHeader",
    components: {},
    props: {
        title: {
            type: String,
        }
    },
    data() {
        return {
            // title: 'Vue Ninjas ATTACK!'
        }
    },
    mounted() {
    },
    methods: {
        changeTitle() {
            // this.title = "Vue Wizards"
            this.$emit('changeTitle', 'Vue Wizards')
        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
header {
    background: lightgreen;
    padding: 10px;
}

h1 {
    color: mediumvioletred;
    text-align: center;
}
</style>
