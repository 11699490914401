<template>
    <div class="align-items-center m-3">
        <h1>Fill Out This Form</h1>
        <hr>
        <form class="m-2">
            <input type="text" placeholder="Starting Zip" v-model="startingZip">
            {{startingCity}}
        </form>
        <form class="m-2">
            <input type="text" placeholder="Ending Zip" v-model="endingZip">
            {{endingCity}}
        </form>
        <!-- tale button nima funkcije -->
        <b-button class="m-2">Submit</b-button>
    </div>
</template>

<!--<script src="https://unpkg.com/axios@0.12.0/dist/axios.min.js"></script>-->
<!--<script src="https://unpkg.com/lodash@4.13.1/lodash.min.js"></script>-->

<script>
export default {
    name: "PostalCodes",
    components: {},
    props: {},
    data() {
        return {
            startingZip: '',
            startingCity: '',
            endingZip: '',
            endingCity: '',
        }
    },
    mounted() {
    },
    methods: {
        lookupStartingZip() {
            this.axios.get('http://ZiptasticAPI.com/' + this.startingZip)
                .then((response) => {
                    this.startingCity = response.data.city + ', ' + response.data.state
                    console.log("response", response.data); // TODO delete
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                    // this.startingCity = "no zip code"
                });
        },
        lookupEndingZip() {
            this.axios.get('http://ZiptasticAPI.com/' + this.endingZip)
                .then((response) => {
                    this.endingCity = response.data.city + ', ' + response.data.state
                    // console.log("response", response.data); // TODO delete
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.endingCity = "no zip code"
                });
        }
    },
    computed: {},
    filters: {},
    watch: {
        startingZip() {
            this.startingCity = ''
            if (this.startingZip.length == 5) {
                //run API method
                this.lookupStartingZip()
            }
        },
        endingZip() {
            this.endingCity = ''
            if (this.endingZip.length == 5) {
                //run API method
                this.lookupEndingZip()
            }
        }
    }
}
</script>

<style scoped>

</style>
