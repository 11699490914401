<template>
    <div>
        <slots-form>
            <div slot="form-header">
                <h3>Form One - Contact Us</h3>
                <p>Fill in this form to contact us</p>
            </div>
            <div slot="form-fields">
                <input type="text" placeholder="name" required />
                <label>Your Message:</label>
                <textarea></textarea>
            </div>
            <div slot="form-controls">
                <button v-on:click="handleSubmit">Send</button>
            </div>
        </slots-form>
    </div>
</template>

<script>
import SlotsForm from "@/components/developer/mojca/SlotsForm";
export default {
    name: "FormOne",
    components: {SlotsForm},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        handleSubmit: function() {
            alert('thanks for submitting form one & contacting us');
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
